<template>
  <v-container id="inspire" fluid>
    <v-row class="fill-height">
      <v-col>
        <template v-if="!vehicleId && !clientsPage">
          <v-sheet>
            <v-row>
              <v-col lg="6" md="6" sm="12">
                <v-toolbar class="toolbar-flex" flat>
                  <v-btn class="mr-4" color="grey darken-2" outlined @click="setToday">
                    {{ $t('buttons.today') }}
                  </v-btn>
                  <v-menu bottom right>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn color="grey darken-2" outlined v-bind="attrs" v-on="on">
                        <!-- Show additional type if one is selected. Additonal types are types that are not acceptable by calendar -->
                        <span>{{ $t(typeToLabel[additionalType ? additionalType : type]) }}</span>
                        <v-icon right> mdi-menu-down </v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item @click="type = changeTypeOfCalendar('day')">
                        <v-list-item-title>
                          {{ $t('filters.day') }}
                        </v-list-item-title>
                      </v-list-item>
                      <v-list-item @click="type = changeTypeOfCalendar('week')">
                        <v-list-item-title>
                          {{ $t('filters.week') }}
                        </v-list-item-title>
                      </v-list-item>
                      <v-list-item @click="type = changeTypeOfCalendar('month')">
                        <v-list-item-title>
                          {{ $t('filters.month') }}
                        </v-list-item-title>
                      </v-list-item>
                      <v-list-item @click="type = changeTypeOfCalendar('halfYear')">
                        <v-list-item-title>
                          {{ $t('filters.halfYear') }}
                        </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                  <div class="toolbar-flex-div"></div>
                  <v-btn color="grey darken-2" fab small text @click="prev">
                    <v-icon small> mdi-chevron-left </v-icon>
                  </v-btn>
                  <v-btn color="grey darken-2" fab small text @click="next">
                    <v-icon small> mdi-chevron-right </v-icon>
                  </v-btn>
                  <v-toolbar-title v-if="$refs.calendar">
                    {{ calendarDate }}
                  </v-toolbar-title>
                </v-toolbar>
              </v-col>
              <v-col cols="12" lg="2" md="2" sm="6" v-if="user.role_id != 11">
                <v-autocomplete
                  v-model="driver_id"
                  :items="drivers"
                  clearable
                  item-text="full_name"
                  item-value="id"
                  :label="$t('filters.filterByDriver')"
                  @change="loadAllDrivings"
                  :no-data-text="$t('select.noDataAvailable')"
                  @focus="$event.target.click()"></v-autocomplete>
              </v-col>
              <v-col cols="12" lg="2" md="2" sm="6" v-if="user.role_id != 11">
                <v-autocomplete
                  v-model="vehicle_id"
                  :items="vehicles"
                  clearable
                  item-text="name"
                  item-value="id"
                  :label="$t('filters.filterByVehicle')"
                  @change="loadAllDrivings"
                  :no-data-text="$t('select.noDataAvailable')"
                  @focus="$event.target.click()"></v-autocomplete>
              </v-col>
              <v-col cols="12" lg="2" md="2" sm="6" v-if="user.role_id != 11">
                <v-autocomplete
                  v-model="client_id"
                  :items="clients"
                  clearable
                  item-text="name"
                  item-value="id"
                  :label="$t('filters.filterByClient')"
                  @change="loadAllDrivings"
                  :no-data-text="$t('select.noDataAvailable')"
                  @focus="$event.target.click()"></v-autocomplete>
              </v-col>
            </v-row> </v-sheet
        ></template>

        <drivings-table
          :drivings="events"
          :drivers="drivers"
          :vehicleId="vehicleId"
          :clientId="clientId"
          :filter_vehicle_id="vehicle_id"
          :filter_client_id="client_id"
          :filter_driver_id="driver_id"
          @show-event="(event) => this.showEvent({ nativeEvent: false, event: event })"
          @duplicate="duplicate"
          @makeReturnTrip="makeReturnTrip">
        </drivings-table>

        <v-sheet height="0">
          <v-calendar
            class="d-none"
            ref="calendar"
            v-model="focus"
            :events="events"
            :interval-format="intervalFormat"
            :type="type"
            color="primary"
            interval-count="48"
            interval-height="60"
            interval-minutes="30"
            @change="updateRange"
            @click:event="showEvent"
            @click:more="viewDay"
            @click:date="viewDay"
            @click:time="showEvent">
            <template v-slot:event="{ event }">
              <div>
                <p style="margin-left: 5px">{{ event.name }} {{ event.start_display_formated }}</p>
              </div>
            </template>
          </v-calendar>
        </v-sheet>
      </v-col>

      <driving-modal
        v-if="
          this.$store.state.auth.role === 1 || this.$store.state.auth.role === 2 || this.$store.state.auth.role === 4
        "
        :selectedElement="selectedElement"
        ref="drivingModal">
      </driving-modal>

      <customer-driving-modal
        v-if="this.$store.state.auth.role === 11"
        :selectedElement="selectedElement"
        ref="drivingModal" />
    </v-row>
  </v-container>
</template>

<script>
import i18n from '@/i18n/i18n';
import { defaultDriving } from '@/mixins/default-items';
import DrivingsTable from './DrivingsTable.vue';
import DrivingModal from './DrivingModal.vue';
import CustomerDrivingModal from '@/components/customers/drivings/CustomersDrivingModal.vue';
import { formatDateTime } from '@/utils/formatDate';
import { changeColorBasedOnProposalStatus } from '@/utils/changeColorBasedOnProposalStatus';

export default {
  name: 'TabularView',
  components: { DrivingsTable, DrivingModal, CustomerDrivingModal },
  props: ['vehicleId', 'vehiclesPage', 'clientId', 'clientsPage'],
  data: () => ({
    focus: '',
    type: 'month',
    additionalType: '',
    typeToLabel: {
      month: 'filters.month',
      week: 'filters.week',
      day: 'filters.day',
      halfYear: 'filters.halfYear',
    },
    driving: {},
    selectedElement: null,
    events: [],
    vehicle_id: null,
    driver_id: null,
    client_id: null,
    vehicle: {},
    min: null,
    max: null,
    calendarDate: '',
    user: {},
  }),

  created() {
    this.selectedElement = Object.assign({}, defaultDriving);
    if (this.$route.query.driving_id) {
      this.showSingleDriving(this.$route.query.driving_id);
    }

    this.user = this.$store.getters['auth/user'];

    if (this.$route.query.driver_id) {
      this.driver_id = parseInt(this.$route.query.driver_id);
    }
  },

  computed: {
    clients() {
      return this.$store.getters['clients/getClients'];
    },

    drivers() {
      return this.$store.getters['users/getDrivers'].map((item) => {
        return {
          ...item,
          full_name: item.profile?.full_name || 'N/A',
        };
      });
    },

    vehicles() {
      return this.$store.getters['vehicles/getVehicles'];
    },
  },

  mounted() {
    this.$refs.calendar.checkChange();
    this.calendarDate = this.formatTitle(this.$refs.calendar.title);
  },

  methods: {
    showSingleDriving(id, action = false) {
      this.$store
        .dispatch('drivings/getDriving', id)
        .then((res) => {
          res.data.color = changeColorBasedOnProposalStatus(res.data?.drivingProposal?.drivingProposalStatus?.name);
          // we keep original value of pickupt time for comparing date
          res.data.pickup_time_original = res.data.pickup_time;
          // we keep format pickup time for preview
          res.data.pickup_time = formatDateTime(res.data.pickup_time);
          res.data.expected_drop_off_time = formatDateTime(res.data.expected_drop_off_time);
          res.data.expected_comeback_time = formatDateTime(res.data.expected_comeback_time);
          res.data.created_at = formatDateTime(res.data.created_at);
          res.data.driving_number = res.data.number + ` [${res.data.public_number}]`;
          this.showEvent({ nativeEvent: false, event: res.data });
        })
        .then(() => {
          if (action && action == 'duplicate') {
            this.$refs.drivingModal.duplicate();
          } else if (action && action == 'makeReturnTrip') {
            this.$refs.drivingModal.makeReturnTrip();
          }
        });
    },

    async loadAllDrivings() {
      let queryData = {
        from: this.vehiclesPage ? null : this.min,
        to: this.vehiclesPage ? null : this.max,
        vehicle_id: this.vehiclesPage ? this.vehicleId : this.vehicle_id,
        driver_id: this.driver_id,
        client_id: this.client_id,
      };

      let path = 'getAllDrivings';

      if (this.user.role_id == 11) {
        path = 'getAllCustomerDrivings';
      } else if (this.clientsPage) {
        path = 'getAllDrivingsForClient';

        if (this.clientId)
          queryData = {
            client_id: this.clientId,
          };
      }

      await this.$store.dispatch('drivings/' + path, queryData).then((res) => {
        this.events = res.data.map((e) => {
          if (e.drivingType.id == 2) {
            e.drivingTypeTranslated = i18n.t('header.dailyRent');
          } else if (e.drivingType.id == 1) {
            e.drivingTypeTranslated = i18n.t('header.transfer');
          } else {
            e.drivingTypeTranslated = '';
          }
          e.distance = parseFloat(e.distance);
          e.price = parseFloat(e.price);
          e.paid = parseInt(e.paid);
          e.from_location ? (e.from_location_name = JSON.parse(e.from_location).name) : '';
          e.to_location ? (e.to_location_name = JSON.parse(e.to_location).name) : '';
          let proposalStatus = e?.drivingProposal?.drivingProposalStatus?.name;
          e.color = changeColorBasedOnProposalStatus(proposalStatus);
          // we keep original value of pickupt time for comparing date
          e.pickup_time_original = e.pickup_time;
          // we keep format pickup time for preview
          e.pickup_time = formatDateTime(e.pickup_time);
          e.expected_drop_off_time = formatDateTime(e.expected_drop_off_time);
          e.expected_comeback_time = formatDateTime(e.expected_comeback_time);
          e.created_at = formatDateTime(e.created_at);
          e.driving_number = e.number + ` [${e.public_number}]`;
          return e;
        });
      });
    },

    viewDay({ date }) {
      this.focus = date;
      this.type = 'day';
    },
    setToday() {
      this.focus = new Date();
      this.type = 'day';
      this.changeTypeOfCalendar('day');
    },
    prev() {
      if (this.additionalType == 'halfYear') {
        this.updateLargeRange(6, 'prev');
      } else {
        this.$refs.calendar.prev();
        this.changeTypeOfCalendar(this.type);
      }
    },
    next() {
      if (this.additionalType == 'halfYear') {
        this.updateLargeRange(6, 'next');
      } else {
        this.$refs.calendar.next();
        this.changeTypeOfCalendar(this.type);
      }
    },
    showEvent({ nativeEvent, event }) {
      this.selectedElement = Object.assign({}, event);

      if (nativeEvent) {
        nativeEvent.stopPropagation();
      }
    },
    updateRange({ start, end }) {
      const min = new Date(`${start.date}`).toISOString();
      const max = new Date(`${end.date}`).toISOString();
      this.min = min;
      this.max = max;
      this.loadAllDrivings();
    },

    intervalFormat(interval) {
      return interval.time;
    },

    changeTypeOfCalendar(type) {
      if (type === 'day' || type === 'week') {
        setTimeout(() => this.$refs.calendar.scrollToTime(475), 100);
      }
      if (type === 'halfYear') {
        this.additionalType = 'halfYear';
      } else {
        // check if additional type is currently selected and we are trying to select regular type which was last selected before the additional one and we need to manually trigger the calendar
        if (this.additionalType == 'halfYear' && type == this.type) {
          this.updateRange({
            start: { date: this.$refs.calendar.lastStart.date },
            end: { date: this.$refs.calendar.lastEnd.date },
          });
        }
        this.additionalType = '';
      }
      if (type == 'week') {
        setTimeout(() => {
          this.calendarDate =
            this.formatDate(this.$refs.calendar.lastStart.date) +
            '-' +
            this.formatDate(this.$refs.calendar.lastEnd.date);
        }, 0);
      } else if (type == 'day') {
        setTimeout(() => {
          this.calendarDate = this.formatDate(this.$refs.calendar.lastStart.date);
        }, 0);
      } else if (type == 'month') {
        setTimeout(() => {
          this.calendarDate = this.formatTitle(this.$refs.calendar.title);
        }, 0);
      } else if (type == 'halfYear') {
        this.updateLargeRange(6);

        return this.type;
      }

      return type;
    },

    // Format the dates as yyyy-mm-dd
    formatDateToYYYYMMDD(date) {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
      const day = String(date.getDate()).padStart(2, '0');

      return `${year}-${month}-${day}`;
    },

    updateLargeRange(range, direction) {
      let startDate = new Date(this.min);
      let endDate = new Date(this.min);

      if (direction == 'prev') {
        // Set startDate to range months earlier and first day of the month
        startDate.setMonth(startDate.getMonth() - range);
        startDate.setDate(1);

        endDate.setDate(0);
      } else if (direction == 'next') {
        // Set startDate to tange forward and first day of the month
        startDate.setMonth(startDate.getMonth() + range);
        startDate.setDate(1);

        // Set endDate to range * 2 months forward and last day of previous month
        endDate.setMonth(endDate.getMonth() + range * 2);
        endDate.setDate(0);
      } else {
        startDate.setMonth(startDate.getMonth() - range); // Set the date to range months earlier
        startDate.setDate(1); // Set date to first day of month
        endDate.setDate(0); // Set date to last day of previous month
      }

      const start = { date: this.formatDateToYYYYMMDD(startDate) };
      const end = { date: this.formatDateToYYYYMMDD(endDate) };

      setTimeout(() => {
        this.calendarDate = this.formatDate(start.date) + '-' + this.formatDate(end.date);
      }, 0);

      // Call updateRange with the calculated dates
      this.updateRange({ start, end });
    },

    formatDate(inputDate) {
      const parts = inputDate.split('-');
      const year = parseInt(parts[0]);
      const month = parseInt(parts[1]) - 1;
      const day = parseInt(parts[2]);

      const formattedDate = `${day.toString().padStart(2, '0')}.${(month + 1).toString().padStart(2, '0')}.${year}`;

      return formattedDate;
    },
    formatTitle(title) {
      const monthNames = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ];
      const monthsTranslations = {
        0: 'months.jan',
        1: 'months.feb',
        2: 'months.mar',
        3: 'months.apr',
        4: 'months.may',
        5: 'months.jun',
        6: 'months.jul',
        7: 'months.aug',
        8: 'months.sep',
        9: 'months.oct',
        10: 'months.nov',
        11: 'months.dec',
      };

      let month = title.slice(0, -5);
      const year = title.slice(-4);

      monthNames.map((item, index) => {
        if (item === month) {
          month = monthsTranslations[index];
        }
      });
      return i18n.t(month) + ' ' + year;
    },

    duplicate(item) {
      this.showSingleDriving(item.id, 'duplicate');
    },
    makeReturnTrip(item) {
      this.showSingleDriving(item.id, 'makeReturnTrip');
    },
  },
  watch: {
    '$store.state.addedNewDrivingCounter': {
      immediate: false,
      handler() {
        this.loadAllDrivings();
      },
    },
    '$store.state.notificationDriving.id': {
      handler() {
        this.showSingleDriving(this.$store.state.notificationDriving.id);
        this.$store.commit('updateNotificationDrivingReload');
        this.loadAllDrivings();
      },
      deep: false,
    },
    '$store.state.notificationDriving.reload': {
      handler() {
        if (this.$store.state.notificationDriving.reload) {
          this.showSingleDriving(this.$store.state.notificationDriving.id);
          this.$store.commit('updateNotificationDrivingReload');
          this.loadAllDrivings();
        }
      },
      deep: false,
    },
    '$i18n.locale': {
      handler() {
        this.calendarDate = this.formatTitle(this.$refs.calendar.title);
      },
    },
    vehicleId: {
      handler() {
        this.loadAllDrivings();
      },
    },
    clientId: {
      handler() {
        this.loadAllDrivings();
      },
    },
  },
};
</script>

<style>
@media only screen and (max-width: 480px) {
  .toolbar-flex > .v-toolbar__content {
    flex-wrap: wrap;
  }

  .toolbar-flex-div {
    flex-basis: available;
    margin-right: 100%;
  }
}
</style>
